import styled from 'styled-components'

import { hideBlockAt, marginBreakpoints } from '../../../../utils/commonAttrs'
import { BaseBlockProps } from '../../../../types/BaseBlockProps'
import { spacing } from '../../../../utils/ui/spacing'

export const Card = styled.div<BaseBlockProps>`
  ${({ marginBottom }) => marginBreakpoints({ marginBottom }, 20)};
  ${({ hiddenAt }) => hideBlockAt({ hiddenAt })};
  border: 1px solid ${({ theme }) => theme.tokens.interface.neutral.light};
  border-radius: 4px;
  overflow: hidden;
`

export const TopBar = styled.div`
  padding: 20px 10px;
  font-family: ${({ theme }) => theme.typography.font.family.title};
  font-weight: ${({ theme }) => theme.typography.font.weight.semibold};
  font-size: 12px;
  letter-spacing: 1px;
  text-align: center;
  text-transform: uppercase;
  color: ${({ theme }) => theme.tokens.accent.onToken.secondary.regular};
  padding: 20px 10px;
  background-color: ${({ theme }) =>
    theme.tokens.accent.token.secondary.regular};
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const TextArea = styled.div`
  border-radius: 0 0 3px 3px;
  padding: 29px 15px 20px;
  line-height: 64px;
  background-color: ${({ theme }) => theme.tokens.space.surface.regular};
`

export const StatisticNumber = styled.div`
  font-size: 45px;
  font-weight: 300;
  text-align: center;
  margin-bottom: 10px;
`

export const BottomText = styled.div`
  width: 100%;
  font-size: 14px;
  text-align: center;
  line-height: 24px;
  margin-bottom: ${spacing('xs')};
`
