import React, { FC } from 'react'

import parser from '../../../../utils/htmlParser'
import { BaseBlockProps } from '../../../../types/BaseBlockProps'
import { BottomText, Card, StatisticNumber, TextArea, TopBar } from './Pricing.styles'

type Props = BaseBlockProps & {
  number: string
  topText: string
  bottomText: string
}

export const Pricing: FC<Props> = ({
  id,
  number,
  topText,
  bottomText,
  marginBottom,
  hiddenAt,
}) => (
  <Card id={id} marginBottom={marginBottom} hiddenAt={hiddenAt}>
    <TopBar>{parser(topText)}</TopBar>
    <TextArea>
      <StatisticNumber>{number}</StatisticNumber>
      <BottomText>{parser(bottomText)}</BottomText>
    </TextArea>
  </Card>
)
